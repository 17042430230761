import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Alert, Spinner } from 'react-bootstrap';
import { SurveyHelper } from './helper';
import FooterComponent from './FooterComponent';

import './SymptomComponent.css';

class SymptomPage extends Component {

    constructor(props) {
        super(props);
      }

    render() {
      var alertComponent = (this.props.loadSuccess == false) ? 
      <Alert variant='danger'>{this.props.errorMessage}</Alert> : 
        null;
    
      var symptomComponent = ((this.props.loadSuccess == false) && (this.props.api == 'getAllData')) ?
      null : 
       this.props.symptomComponent;
    
      var alertAndSymptomComponent = <>{alertComponent} {symptomComponent}</>;
    
      var spinnerComponent = this.props.currentlyLoading ? <Spinner animation='border' /> : null;

      return(
        <>
        {spinnerComponent ? spinnerComponent : alertAndSymptomComponent}
        <FooterComponent />
          </>
      );

    }

}

// redux stuff
const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(SymptomPage);