import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './TextPage.css';
import FooterComponent from './FooterComponent';
import { Helmet } from 'react-helmet';


class AboutComponent extends Component {

  constructor(props) {
    super(props);
  }

render() {

return(
  <>
      <Helmet>
     <title>About</title>
        <meta name="description" content="Learn why the long covid data center was created." />
    </Helmet>
    <Container>
    <div>
        <h1 className="top-header"> About </h1>
        <p className='default-row-spacer'>I got COVID in March 2020, and I've been a long hauler ever since. My mission is to find possible supplement, diet, and lifestyle changes that can improve long covid symptoms.</p>
        <p className='default-row-spacer'>To that end, I've created a survey for other long haulers. The survey should take no longer than 15 minutes to complete and is completely anonymous. On the website homepage, you can see statistics from all the surveys that have been collected so far. Please fill the survey out! Every completed survey improves the data by a little bit, and every little bit matters in the end.  
            I hope this website is helpful, please share it with those that could benefit. Thank you!</p>
        <p className='default-row-spacer'><span style={{color: 'brown', fontWeight: 'bold'}}>Disclaimer</span>: The information on this website does not constitute medical advice. Please consult a medical professional before making any treatment decisions based on this website's data.</p> 
    </div>
    <FooterComponent/>
    </Container>
  </>

);
}
}

export default AboutComponent;