import React, { Component } from 'react';
import SurveyComponent from './SurveyComponent';
import AboutComponent from './AboutComponent';
import NotFoundComponent from './NotFound';
import Navigation from './Navigation';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import SymptomPage from './SymptomPage';
import store from './store'
import { getAllData } from './actions'
import { Helmet } from 'react-helmet';

class App extends Component {

    componentDidMount() {
        store.dispatch(getAllData());
    }

    render() {

    return (        
        <>            
              <Helmet>
     <title>Long Covid Data Center</title>
        <meta name="description" content="Get data about what improves long covid symptoms111." />
      </Helmet>
            <BrowserRouter>
            <Navigation/>
              <Routes>                
                 <Route path='symptomData' element={<SymptomPage/>}/>  
                 <Route path='survey' element={<SurveyComponent/>}/>
                 <Route path='about' element={<AboutComponent/>}/>
                 <Route path='/' element={<SymptomPage/>}/>     
                 <Route path='*' element={<NotFoundComponent/>}/>          
              </Routes>
              </BrowserRouter>
        </>
    );
    }
}

export default App;