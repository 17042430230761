import { SurveyHelper } from './helper';
import SymptomComponent from './SymptomComponent';
import * as Survey from "survey-react";

// TODO can getallData be called when user is already entering survey? This would overwrite then.
export default function rootReducer(state = {}, action) {
  // right now the only thing in react store is api fetch results,
  switch(action.type) {
    case 'GETALLDATA_API_SUCCESS':
        const survey = SurveyHelper.createSurvey(action.payload.requestOutput.data);
        const symptomComponent = <SymptomComponent symptomData={action.payload.requestOutput.data} />
        return {"currentlyLoading": false, loadSuccess: true, symptomData: action.payload.requestOutput.data, symptomComponent: symptomComponent,
        "survey": survey}
    case 'GETALLDATA_API_FAILURE':
        return {"currentlyLoading": false, loadSuccess: false, errorMessage: 'Data loading failed, please try again later.', api: 'getAllData',
        "survey": null}
    case 'GETALLDATA_API_LOADING':
        return {"currentlyLoading": true, 
        "survey": null}
    case 'SUBMITSURVEY_API_SUCCESS':
        var stateCopy = {...state};
        return {"currentlyLoading": false, loadSuccess: true, symptomData: stateCopy.data, symptomComponent: stateCopy.symptomComponent,
        "survey": <Survey.Survey model={action.payload.survey} onComplete={SurveyHelper.onComplete}/>}
    case 'SUBMITSURVEY_API_FAILURE':
        var stateCopy = {...state};
        action.payload.survey.clear(false, false);
        return {"currentlyLoading": false, loadSuccess: false, symptomData: stateCopy.data, symptomComponent: stateCopy.symptomComponent, 
        errorMessage: 'Submit survey failed, please try again later.', api: 'submitSurvey',
        "survey": <Survey.Survey model={action.payload.survey} onComplete={SurveyHelper.onComplete}/>}
    case 'SUBMITSURVEY_API_LOADING':
        var stateCopy = {...state};
        return {"currentlyLoading": true, symptomData: stateCopy.data, symptomComponent: stateCopy.symptomComponent,
        "survey": <Survey.Survey model={action.payload.survey} onComplete={SurveyHelper.onComplete}/>}
    default:
        return state;
      }
}