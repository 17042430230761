import React, { Component } from 'react';
import { connect, connectAdvanced } from 'react-redux';
import { Alert, Spinner } from 'react-bootstrap';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { SurveyHelper } from './helper';
import store from './store'
import { fetchPosts } from './actions'
import FooterComponent from './FooterComponent';
import { Helmet } from 'react-helmet';


import './SurveyComponent.css';

class SurveyComponent extends Component {

  constructor(props) {
    super(props);
  }

render() {

  var alertComponent = (this.props.loadSuccess == false) ? 
  <Alert variant='danger'>{this.props.errorMessage}</Alert> : 
    null;

  var surveyComponent = ((this.props.loadSuccess == false) && (this.props.api == 'getAllData')) ?
  null : 
   this.props.survey;

  var alertAndSurveyComponent = <>{alertComponent} {surveyComponent}</>;

  var spinnerComponent = this.props.currentlyLoading ? <Spinner animation='border' /> : null;


return(
  <>
      <Helmet>
     <title>Survey</title>
        <meta name="description" content="Take the long haul covid survey." />
    </Helmet>
  {spinnerComponent ? spinnerComponent : alertAndSurveyComponent}
  <FooterComponent />
    </>
);
}
}

// redux stuff
const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(SurveyComponent);

