import React, { Component } from 'react';

class NotFoundComponent extends Component {

  constructor(props) {
    super(props);
  }

render() {

return(
    <div
    style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
    }}
    >
    <h3>This page does not exist.</h3>
    
  </div>
);
}
}

export default NotFoundComponent;

