import axios from 'axios'
import apiUrl from './cdk-outputs.json'

const GETALLDATA_API_SUCCESS = requestOutput => ({
    type: 'GETALLDATA_API_SUCCESS',
    payload: { requestOutput }
})

const GETALLDATA_API_FAILURE = () => ({
    type: 'GETALLDATA_API_FAILURE',
    payload: {}
})

const GETALLDATA_API_LOADING = () => ({
    type: 'GETALLDATA_API_LOADING',
    payload: {}
})

const SUBMITSURVEY_API_SUCCESS = (survey) => ({
    type: 'SUBMITSURVEY_API_SUCCESS',
    payload: { survey }
})

const SUBMITSURVEY_API_FAILURE = (survey) => ({
    type: 'SUBMITSURVEY_API_FAILURE',
    payload: { survey }
})

const SUBMITSURVEY_API_LOADING = (survey) => ({
    type: 'SUBMITSURVEY_API_LOADING',
    payload: { survey }
})

export const getAllData = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(GETALLDATA_API_LOADING())
            // check if static data already loaded
            if (getState().data) {
                return;
            }
            console.log("api call happening");
            let requestOutput = await axios.get(apiUrl.BackendStack.apiUrl + 'staticData')
            dispatch(GETALLDATA_API_SUCCESS(requestOutput)) 
        }
        catch(e){
            dispatch(GETALLDATA_API_FAILURE()) 
            console.log(e)
        }
    }
}

export const submitSurvey = (survey) => {
    return async (dispatch) => {
        try {
            dispatch(SUBMITSURVEY_API_LOADING(survey))
            //Write survey results into database
            var surveyData = JSON.stringify(survey.data);
            console.log("Survey results: " + surveyData);
            await axios.post(apiUrl.BackendStack.apiUrl + 'submitSurvey', surveyData);
            dispatch(SUBMITSURVEY_API_SUCCESS(survey)) 
        }
        catch(e){
            dispatch(SUBMITSURVEY_API_FAILURE(survey)) 
            console.log(e)
        }
    }
}