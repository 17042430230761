import React, { Component } from 'react';
import {Box} from './FooterStyles.js';

class FooterComponent extends Component {

  constructor(props) {
    super(props);
  }

render() {

return(
  <Box>
    <p style={{ color: "grey", 
                   textAlign: "center", 
                   marginTop: "-10px" }}>
        Contact longcoviddata@gmail.com if you have questions/comments.
    </p>
  </Box>
);
}
}

export default FooterComponent;



