import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  
return (
    <>
<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Container>
  <Navbar.Brand as={Link} to="/symptomData">Long Covid Data Center</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
    <Nav className="me-auto" defaultActiveKey="symptomData">
      <Nav.Link as={Link} eventKey="symptomData" style={{color:"white"}} to="/symptomData">Symptom Data</Nav.Link>
      <Nav.Link as={Link} eventKey="survey" style={{color:"#69F0AE"}} to="/survey">Take the Survey</Nav.Link>
      <Nav.Link as={Link} eventKey="about" style={{color:"white"}} to="/about">About</Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
    </>
);
}

export default Navigation;