import React, { Component } from 'react';
import "survey-react/survey.css";
import { Container, Form, ButtonGroup, Button, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './SymptomComponent.css';
import { Helmet } from 'react-helmet';

export default class SymptomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            symptom: ''
        };
      }

    prepareSupplementData(sData) {

      // first figure out the unit
      const unitDict = sData.totalSymptomCountDict;
      const mostCommonUnit = Object.keys(sData.totalSymptomCountDict).sort((a, b) => unitDict[b] - unitDict[a])[0];

      return {
        usersTried: sData.totalSymptomCount,
        percentHelped: (sData.helpSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%', 
        percentHurt: (sData.hurtSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%',
        supplementName: this.props.symptomData.supplementList[sData.supplement_id],
        averageDosage: Number(sData.symptomAverageDosageDict[mostCommonUnit]).toFixed(0) + ' ' + mostCommonUnit
      };
    }

    prepareFoodData(sData) {
      return {
        usersTried: sData.totalSymptomCount,
        percentHelped: (sData.helpSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%', 
        percentHurt: (sData.hurtSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%',
        foodName: this.props.symptomData.foodList[sData.food_id],
      };
    }

    prepareActivityData(sData) {
      return {
        usersTried: sData.totalSymptomCount,
        percentHelped: (sData.helpSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%', 
        percentHurt: (sData.hurtSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%',
        activityName: this.props.symptomData.activityList[sData.activity_id],
      };
    }

    prepareVaccineData(sData) {
      return {
        usersTried: sData.totalSymptomCount,
        percentHelped: (sData.helpSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%', 
        percentHurt: (sData.hurtSymptomCount * 100 / sData.totalSymptomCount).toFixed(0).toString() + '%',
        vaccineName: this.props.symptomData.vaccineList[sData.vaccine_id],
      };
    }

    handleSupplementButtonClick=(e)=>{
      console.log(e.target.value)
    }

    supplementCellFormatter(cell, row, rowIndex, formatExtraData) {
      console.log(cell);
      console.log(row);
      console.log(rowIndex);
      console.log(formatExtraData);
      return ( <>{row.averageDosage}</> ); }
  
    render() {
      const sortedKeys = Object.keys(this.props.symptomData.symptomList).sort((a, b) => {
        return this.props.symptomData.symptomList[a].localeCompare(this.props.symptomData.symptomList[b])
      });

      var symptomPercentage;
      var totalSymptomCount;
      var totalCount;
      var symptomAverageAge;
      var symptomAverageLHMonths;
      var symptomAverageSeverity;
      var supplementTableData;
      var foodTableData;
      var activityTableData;
      var vaccineTableData;
      var symptomDataSelected = this.state.symptom && this.state.symptom != 'Choose Symptom';
      var symptomDataExists = this.state.symptom && this.state.symptom != 'Choose Symptom' && this.props.symptomData.symptoms_detail[this.state.symptom].symptom.length == 1;
      var supplementDataExists = symptomDataSelected && this.props.symptomData.symptoms_detail[this.state.symptom].symptom_supplement.length > 0;
      var foodDataExists = symptomDataSelected && this.props.symptomData.symptoms_detail[this.state.symptom].symptom_food.length > 0;
      var activityDataExists = symptomDataSelected && this.props.symptomData.symptoms_detail[this.state.symptom].symptom_activity.length > 0;
      var vaccineDataExists = symptomDataSelected && this.props.symptomData.symptoms_detail[this.state.symptom].symptom_vaccine.length > 0;

      if (symptomDataSelected && symptomDataExists) {
        symptomPercentage = (this.props.symptomData.symptoms_detail[this.state.symptom].symptom[0].totalCount * 100 / this.props.symptomData.total_survey_count).toFixed(0);
        totalSymptomCount = this.props.symptomData.symptoms_detail[this.state.symptom].symptom[0].totalCount;
        totalCount = this.props.symptomData.total_survey_count;
        symptomAverageAge = Number(this.props.symptomData.symptoms_detail[this.state.symptom].symptom[0].symptomAverageAge).toFixed(0);
        symptomAverageLHMonths = Number(this.props.symptomData.symptoms_detail[this.state.symptom].symptom[0].symptomAverageLHMonths).toFixed(2);
        symptomAverageSeverity = Number(this.props.symptomData.symptoms_detail[this.state.symptom].symptom[0].symptomAverageSeverity).toFixed(2);
      }

      if (supplementDataExists) {
        supplementTableData = this.props.symptomData.symptoms_detail[this.state.symptom].symptom_supplement.map(this.prepareSupplementData, this);
      }

      if (foodDataExists) {
        foodTableData = this.props.symptomData.symptoms_detail[this.state.symptom].symptom_food.map(this.prepareFoodData, this);
      }

      if (activityDataExists) {
        activityTableData = this.props.symptomData.symptoms_detail[this.state.symptom].symptom_activity.map(this.prepareActivityData, this);
      }

      if (vaccineDataExists) {
        vaccineTableData = this.props.symptomData.symptoms_detail[this.state.symptom].symptom_vaccine.map(this.prepareVaccineData, this);
      }

      var symptomPercentageSentence = <><span style={{color: 'brown', fontWeight: 'bold'}}>{symptomPercentage}% ({totalSymptomCount}/{totalCount})</span> of users have reported this symptom. </>; 
      var severitySentence = <>The average severity of this symptom is <span style={{color: 'brown', fontWeight: 'bold'}}>{symptomAverageSeverity}/10</span>. </>;
      var ageSentence = <>The average age of people with symptom is <span style={{color: 'brown', fontWeight: 'bold'}}>{symptomAverageAge}</span> years. </>;
      var lhmonthsSentence = <>The average long haul duration of people with symptom is <span style={{color: 'brown', fontWeight: 'bold'}}>{symptomAverageLHMonths}</span> months. </>;
      var notEnoughDataSentence = <>There is not enough data for this symptom. </>;

      var allSymptomSentence = symptomDataExists ? <p>{symptomPercentageSentence}{severitySentence}{ageSentence}{lhmonthsSentence}</p> 
      : <p>{notEnoughDataSentence}</p>;

      var allSentence = symptomDataSelected ? allSymptomSentence : null;

      const pagination = paginationFactory({
        sizePerPage: 5,
        hideSizePerPage: true
      });

      const sortFuncPercent = (a, b, order, dataField, rowA, rowB) => {
        if (order === 'desc') {
          return parseInt(b.slice(0, -1)) - parseInt(a.slice(0, -1));
        }
        return parseInt(a.slice(0, -1)) - parseInt(b.slice(0, -1)); // desc
      }

      const sortFuncNumber = (a, b, order, dataField, rowA, rowB) => {
        if (order === 'desc') {
          return parseInt(b) - parseInt(a);
        }
        return parseInt(a) - parseInt(b); // desc
      }

      const supplementTableColumns = [{
        dataField: 'supplementName',
        text: 'Supplement Name'
      }, {
        dataField: 'usersTried',
        text: 'Number of Users',
        sort: true,
        sortFunc: sortFuncNumber
      },{
        dataField: 'percentHelped',
        text: 'Percent of Users Helped',
        sort: true,
        sortFunc: sortFuncPercent
      }, {
        dataField: 'percentHurt',
        text: 'Percent of Users Hurt',
        sort: true,
        sortFunc: sortFuncPercent
      }, {
        dataField: 'averageDosage',
        text: 'Average Daily Dosage',
        formatter: this.supplementCellFormatter
      }];

      const foodTableColumns = [{
        dataField: 'foodName',
        text: 'Food Name'
      }, {
        dataField: 'usersTried',
        text: 'Number of Users',
        sort: true,
        sortFunc: sortFuncNumber
      },{
        dataField: 'percentHelped',
        text: 'Percent of Users Helped',
        sort: true,
        sortFunc: sortFuncPercent
      }, {
        dataField: 'percentHurt',
        text: 'Percent of Users Hurt',
        sort: true,
        sortFunc: sortFuncPercent
      }];

      const activityTableColumns = [{
        dataField: 'activityName',
        text: 'Activity Name'
      }, {
        dataField: 'usersTried',
        text: 'Number of Users',
        sort: true,
        sortFunc: sortFuncNumber
      },{
        dataField: 'percentHelped',
        text: 'Percent of Users Helped',
        sort: true,
        sortFunc: sortFuncPercent
      }, {
        dataField: 'percentHurt',
        text: 'Percent of Users Hurt',
        sort: true,
        sortFunc: sortFuncPercent
      }];

      const vaccineTableColumns = [{
        dataField: 'vaccineName',
        text: 'Vaccine Name'
      }, {
        dataField: 'usersTried',
        text: 'Number of Users',
        sort: true,
        sortFunc: sortFuncNumber
      },{
        dataField: 'percentHelped',
        text: 'Percent of Users Helped',
        sort: true,
        sortFunc: sortFuncPercent
      }, {
        dataField: 'percentHurt',
        text: 'Percent of Users Hurt',
        sort: true,
        sortFunc: sortFuncPercent
      }];

      var supplementTable = supplementDataExists ? <BootstrapTable keyField='supplementName' data={ supplementTableData } columns={ supplementTableColumns } bootstrap4={true} pagination={pagination} sort={ { dataField: 'percentHelped', order: 'desc' }} />
      : notEnoughDataSentence;

      var foodTable = foodDataExists ? <BootstrapTable keyField='foodName' data={ foodTableData } columns={ foodTableColumns } bootstrap4={true} pagination={pagination} sort={ { dataField: 'percentHelped', order: 'desc' }} />
      : notEnoughDataSentence;   
      
      var activityTable = activityDataExists ? <BootstrapTable keyField='activityName' data={ activityTableData } columns={ activityTableColumns } bootstrap4={true} pagination={pagination} sort={ { dataField: 'percentHelped', order: 'desc' }} />
      : notEnoughDataSentence;   

      var vaccineTable = vaccineDataExists ? <BootstrapTable keyField='vaccineName' data={ vaccineTableData } columns={ vaccineTableColumns } bootstrap4={true} pagination={pagination} sort={ { dataField: 'percentHelped', order: 'desc' }} />
      : notEnoughDataSentence;  

        return (
            <>
                  <Helmet>
     <title>Symptom Data</title>
        <meta name="description" content="View data about what improves various long haul symptoms." />
    </Helmet>
            <Container>
              <p className="top-text">Please select a symptom from the box to view collected information. Please consult a medical professional before making treatment decisions based on this data. </p>
    <div className="App">
      <Form.Control as="select"
          onChange={e => {
            this.setState({
                symptom: e.target.value
              });
          }}>
          <option>Choose Symptom</option>
          {sortedKeys.map((key) => {
        return <option value={key}>{this.props.symptomData.symptomList[key]}</option>;
    })}
      </Form.Control>

    </div>

    {symptomDataSelected ? <>
    <div className="symptom-summary">
        <h3>General Information: </h3>
        {allSentence}
    </div>

    <div className="info-table">
    <h3>Supplement data for this symptom: </h3>
    {supplementTable}
    </div>

    <div className="info-table">
    <h3>Food data for this symptom: </h3>
    {foodTable}
    </div>

    <div className="info-table">
    <h3>Activity data for this symptom: </h3>
    {activityTable}
    </div>

    <div className="info-table">
    <h3>Vaccine data for this symptom: </h3>
    {vaccineTable}
    </div>

    <div className="info-table"></div>
    </> : null}

</Container>
</>

);
    }
  
}
