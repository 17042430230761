import * as Survey from "survey-react";
import "survey-react/survey.css";
import axios from 'axios'
import store from './store'
import { submitSurvey } from './actions'

export class SurveyHelper {

  static onComplete(survey, options) {
    store.dispatch(submitSurvey(survey));
   }

  static createSurvey(data) {

      // survey style customization
  var defaultThemeColors = Survey
  .StylesManager
  .ThemeColors["default"];
defaultThemeColors["$body-container-background-color"] = "#ffffff";
Survey
  .StylesManager
  .applyTheme();

var survey = new Survey.Model(SurveyHelper.getBaseSurveyJson(data));

  survey.onCurrentPageChanged.add((sender, options) => {

    if (!((survey.currentPage.name == "page0") || (survey.currentPage.name == "page1"))) {
    const survey = sender;
    const conditionQuestion = survey.getQuestionByName("preexisting_condition_list");
    const supplementQuestion = survey.getQuestionByName("supplement_list");
    const foodQuestion = survey.getQuestionByName("food_list");
    const physicalQuestion = survey.getQuestionByName("physical_activity_list");
    const vaccineQuestion = survey.getQuestionByName("vaccine_list");

  // get the entered symptoms for the rest of the survey
  var symptoms = [];
  survey.data.symptom_list.forEach(row => {
    if ("symptom_name_column-Comment" in row) {
      symptoms.push(row["symptom_name_column-Comment"]);
    }
    else {
    symptoms.push(row["symptom_name_column"]);
    }
  });

  // set the symptoms for all pages
  supplementQuestion.choices = symptoms;
  foodQuestion.choices = symptoms;
  physicalQuestion.choices = symptoms;
  vaccineQuestion.choices = symptoms;
}

  });

  return (<Survey.Survey model={survey} onComplete={SurveyHelper.onComplete}/> )
  }

  static getListWithoutId(richList, shouldSort=true) {
    return shouldSort ? (Object.values(richList).sort()) : (Object.values(richList));
  }

    static getBaseSurveyJson(propsObject) {
        var tenScale = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
     
     var page0 = {
        name: "page0", 
        elements: [
           {
        type: "dropdown",
        name: "gender_info",
        title: "What is your gender?",
        isRequired: true,
        choices: SurveyHelper.getListWithoutId(propsObject.genderList)
        },
        {
          type: "dropdown",
          name: "age_info",
          isRequired: true,
          title: "What is your age?",
          choices: Array.from(Array(100).keys()) // TODO check refresh
        },
        {
          type: "dropdown",
          name: "long_haul_duration_info",
          isRequired: true,
          title: "How many months have you been long hauling?",
          choices: Array.from(Array(30).keys())
        },
        {
          type: "dropdown",
          name: "antibody_test_info",
          isRequired: true,
          title: "Did you test positive for antibodies after you were initially infected with COVID-19?",
          choices: SurveyHelper.getListWithoutId(propsObject.antibodyStatusList)
        },
        {
          type: "matrixdynamic",
          name: "preexisting_condition_list",
          title: "If you had medical conditions (diabetes, asthma, etc.) before developing long haul COVID, please list them below:",
          rowCount: 1,
          addRowText: "Add new condition",
          columns: [
            {
              name:"condition_name",
              title: "Condition Name",
              choices: SurveyHelper.getListWithoutId(propsObject.conditionList),
              hasOther: "true",
              otherText: "Other",
              otherPlaceHolder: "Write condition name here",
              cellType: "dropdown",
              isUnique: true
              }]
             }
        ]
    };
    
    var page1 = {
        name: "page1",
          elements: [
       {
        type: "matrixdynamic",
        name: "symptom_list",
        isRequired: true,
        title: "Please enter your symptoms:",
        rowCount: 1,
        addRowText: "Add new symptom",
        keyDuplicationError: "Duplicate symptoms not allowed",
        columns: [
            {
            name:"symptom_name_column",
            title: "Symptom Name",
            choices: SurveyHelper.getListWithoutId(propsObject.symptomList),
            isRequired: true,
            isUnique: true,
            hasOther: "true",
            otherText: "Other",
            otherPlaceHolder: "Write symptom name here",
            cellType: "dropdown"
            },
            {
            name: "severity_column",
            isRequired: true,
            title: "Severity (out of 10: 1 is mild, 10 is extreme)",
            choices: tenScale
            }]
        }
        ]
    
    }; 
    
    var page2 = {
        name: "page2", 
        elements: [
           {
        type: "matrixdynamic",
        name: "supplement_list",
        title: "Please enter any supplements you take:",
        rowCount: 0,
        addRowText: "Add new supplement",
        keyDuplicationError: "Duplicate supplements not allowed",
        columns: [
            {
            name:"supplement_name_column",
            title: "Supplement Name",
            choices: SurveyHelper.getListWithoutId(propsObject.supplementList),
            hasOther: "true",
            otherText: "Other",
            otherPlaceHolder: "Write supplement name here",
            cellType: "dropdown",
            isRequired: true,
            isUnique: true
            },
            {
            name: "dosage_column",
            title: "Total daily dosage",
            cellType: "text",
            validators: [
              {
                type: "numeric",
                minValue: 0,
                maxValue: 10000
              }
            ],
            isRequired: true
            },
            {
              name: "dosage_unit_column",
              title: "Select Unit",
              choices: ["g", "mg", "mcg", "mL", "IU"],
              cellType: "dropdown",
              isRequired: true
            },
            {
            name: "help_column",
            title: "Mark which symptom(s) this supplement helps with:",
            cellType: "checkbox"
            },
            {
              name: "hurt_column",
              title: "Mark which symptom(s) this supplement makes worse:",
              cellType: "checkbox"
            },
            {
              name: "unsure_column",
              title: "Mark which symptom(s) this supplement has unclear/no effect on:",
              cellType: "checkbox"
            }]
        }
        ]
    };
    
    var page3 = {
        name: "page3", 
        elements: [
           {
        type: "matrixdynamic",
        name: "food_list",
        title: "Please enter any foods you eat:",
        rowCount: 0,
        addRowText: "Add new food",
        keyDuplicationError: "Duplicate foods not allowed",
        columns: [
            {
            name:"food_name_column",
            title: "Food Name",
            choices: SurveyHelper.getListWithoutId(propsObject.foodList),
            hasOther: "true",
            otherText: "Other",
            otherPlaceHolder: "Write food name here",
            cellType: "dropdown",
            isRequired: true,
            isUnique: true
            },
            {
              name: "hurt_column",
              title: "Please mark which symptom(s) this food makes worse:",
              width: "30%",
              cellType: "checkbox"
            },
            {
            name: "help_column",
            title: "Please mark which symptom(s) this food improves:",
            width: "30%",
            cellType: "checkbox"
            }]
        }
        ]
    };
    
    var page4 = {
        name: "page4", 
        elements: [
           {
        type: "matrixdropdown",
        name: "physical_activity_list",
        title: "Please how various levels of physical activity affect your symptoms:",
        columns: [
            {
              name: "hurt_column",
              title: "Please mark which symptom(s) this level of activity makes worse:",
              width: "30%",
              cellType: "checkbox"
            },
            {
            name: "help_column",
            title: "Please mark which symptom(s) this level of activity improves:",
            width: "30%",
            cellType: "checkbox"
            }],
        
        rows: SurveyHelper.getListWithoutId(propsObject.activityList, false)
         }],
    };
    
    var page5 = {
        name: "page5", 
        elements: [
           {
        type: "matrixdynamic",
        name: "vaccine_list",
        title: "If you've had a COVID-19 vaccine, please fill out the below:",
        addRowText: "Add new vaccine",
        keyDuplicationError: "Duplicate vaccine types not allowed",
        rowCount: 0,
        columns: [
            {
            name:"vaccine_name_column",
            title: "Vaccine Type",
            choices: SurveyHelper.getListWithoutId(propsObject.vaccineList),
            isUnique: true,
            isRequired: true
            },
            {
              name: "dosage_column",
              title: "How many doses have you recieved in total?",
              choices: Array.from([1, 2, 3, 4]),
              hasOther: true,
              isRequired: true,
              otherText: "Other",
            },
            {
              name: "hurt_column",
              title: "Please mark which symptom(s) the vaccine made worse:",
              width: "30%",
              cellType: "checkbox"
            },
            {
            name: "help_column",
            title: "Please mark which symptom(s) the vaccine improved:",
            width: "30%",
            cellType: "checkbox"
            }]
        }
        ]
    };
    
    var page6 = {
        name: "page6", 
        elements: [
        {
          type: "matrixdynamic",
          name: "doctor_list",
          addRowText: "Add new doctor",
          title: "If you've met medical professionals that helped your long COVID, please list them below:",
          rowCount: 1,
          columns: [
              {
              name:"doctor_name",
              title:"Doctor Name:",
              cellType: "text"          
            },
            {
              name:"doctor_specialty",
              title: "Doctor Specialty",
              cellType: "text"
              }]
        },
        {
          type: "matrixdynamic",
          name: "resource_list",
          addRowText: "Add new resource",
          title: "If any online resources helped your long COVID, please put the link to them below:",
          rowCount: 1,
          columns: [
              {
              name:"link_name",
              title:"Resource Link:",
              cellType: "text"          
            }]
        },
        {
          type: "comment",
          name: "additional_info",
          rows: "10",
          title: "Is there anything not covered in the survey that you'd like to share? Please answer in the below box.",
        },
        ]
    };
    var json = {
      pages: [page0, page1, page2, page3, page4, page5, page6]
    };
    return json;

     
}
}