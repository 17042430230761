import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store'
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

// Importing the Bootstrap CSS
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const history = createBrowserHistory();

let root = document.getElementById('root');
if (root) {

  // 1. Set up the browser history with the updated location
  // (minus the # sign)
	const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

  ReactDOM.render(
    
  <Provider store={store}>
      <App />
    </Provider>, 
      root);

}