import styled from 'styled-components';
   
export const Box = styled.div`
  padding: 80px 30px;
  background: white;
  position: relative;
  bottom: 0;
  width: 100%;
  
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

